import '../../assets/styles/cart_steps.css';
import { Ecommerce } from './ecommerce';
const cartVersion = 'cartV4';
const ecommerce = new Ecommerce();
const fadeTime = 0;
const taxRate = 0.21;
export function redRawCount() {
    const cartValue = sessionStorage.getItem(cartVersion);
    if (!cartValue) {
        return;
    }
    const cart = JSON.parse(cartValue);
    if (!cart) {
        return;
    }
    let quantitySum = 0;
    let sum = 0;
    let t = 0;
    Object.values(cart).forEach(function (value) {
        quantitySum += Number(value.quantity);
        const val = +(value.price * value.quantity).toFixed(2);
        sum += val;
    });
    t = sum * taxRate;
    $('.js-cart-count').text(String(quantitySum));
    $('.js-cart-sum').text(String((sum + t).toFixed(2)));
}
export function addProductToCart(id, part_number, quantity, price, type, supplier, minQuantity = 1, priceWithVat) {
    const cartValue = sessionStorage.getItem(cartVersion) ?? '{}';
    const cart = JSON.parse(cartValue) ?? {};
    if (cart.hasOwnProperty(id)) {
        delete cart[id];
    }
    cart[id] = {
        id,
        item: part_number,
        price: price,
        quantity: quantity,
        type,
        supplier,
        minQuantity,
        priceWithVat: priceWithVat
    };
    sessionStorage.setItem(cartVersion, JSON.stringify(cart));
    ecommerce.addToCart({
        items: [
            {
                index: 0,
                quantity,
                price: priceWithVat ?? +((price * (1 + taxRate)).toFixed(2)),
                item_id: "" + id,
                item_name: '' + part_number
            }
        ],
        currency: 'EUR',
        value: quantity
    });
    redRawCount();
}
function fillCart() {
    const cartValue = sessionStorage.getItem(cartVersion) ?? '{}';
    const cart = JSON.parse(cartValue);
    const $cartTable = $('#cart_table');
    $cartTable.empty();
    Object.values(cart).forEach((value) => {
        $cartTable.append(`<tr class="product search-results__item" data-id="${value.id}">
    <td>
      <span class="search-results__group">
          <strong>
              <span class="product-type">${value.type}</span>
          </strong>
        </span>
    </td>
    <td>
        <span class="search-results__group">
          <span class="search-results__subtitle">Teikėjas</span>
          <span class="product-supplier">${value.supplier || ''}</span>
        </span>
    </td>
    <td>
        <span class="search-results__group">
          <span class="search-results__subtitle">Kodas</span>
          <span class="product-item t-part-number">${value.item}</span>
        </span>
    </td>    
    <td>
        <span class="search-results__group">
          <span class="search-results__subtitle">Kaina</span>
          <span><span class="product-price">${value.price}</span>€</span>
        </span>          
    </td>
    <td>
        <span class="search-results__number">
            <button class="search-results__action js-quantity-minus">
              <svg width="8" height="2" viewbox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 1H8" stroke="#1C1C1C"/>
              </svg>
            </button>
            <input
                type="number"
                name="quantity[]"
                min="${value.minQuantity ?? 1}"
                class="form-control text-center product-quantity t-quantity js-quantity search-results__quantity"
                value="${value.quantity}"/>
            <button class="search-results__action js-quantity-plus">
                <svg width="9" height="10" viewbox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line x1="4.37114e-08" y1="5.14282" x2="9" y2="5.14282" stroke="#1C1C1C"/>
                  <line x1="4.64355" y1="9.5" x2="4.64355" y2="0.5" stroke="#1C1C1C"/>
                </svg>
            </button>
        </span>
    </td>
    <td class="t-price text-nowrap">
      <span class="search-results__group">
          <span class="search-results__subtitle">Suma</span>
          <span><span class="product-line-price">${(parseFloat(String(value.price).replace(/\s+/, '')) * value.quantity).toFixed(2)}</span>€</span>
      </span>
    </td>
    <td class="text-nowrap">
        <span class="search-results__group">
          <span class="search-results__subtitle">Suma su PVM</span>          
          <strong style="color:#0f4bc3">
            <span class="catalog-price product-full-price">${(parseFloat(String(value.price).replace(/\s+/, '')) * value.quantity * (taxRate + 1)).toFixed(2)}</span>€
          </strong>
        </span>
    </td>
    <td class="actions text-right">
        <input type="hidden" name="partId[]" value="${value.id}"/>
        <button type="button" class="btn js-remove-item search-results__remove">
            <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.44152 0C5.15023 0 4.00381 0.82629 3.59547 2.05132L3.27924 3H2H1C0.447715 3 0 3.44772 0 4C0 4.55228 0.447715 5 1 5V17C1 17.5523 1.44772 18 2 18H12C12.5523 18 13 17.5523 13 17V5C13.5523 5 14 4.55228 14 4C14 3.44772 13.5523 3 13 3H12H10.7208L10.4045 2.05132C9.99619 0.82629 8.84977 0 7.55848 0H6.44152ZM11 5H10H4H3V16H11V5ZM8.61257 3L8.50716 2.68377C8.37105 2.27543 7.98891 2 7.55848 2H6.44152C6.01109 2 5.62895 2.27543 5.49284 2.68377L5.38743 3H8.61257Z" fill="#B4BAC3" />
            </svg>
        </button>
    </td>
</tr>`);
    });
    recalculateCart();
}
function removeItem(removeButton) {
    const $productRow = $(removeButton).parents('tr');
    const id = $productRow.data('id');
    $productRow.slideUp(fadeTime, () => {
        $productRow.remove();
        removeProductFromCart(id);
        recalculateCart();
    });
}
function recalculateCart() {
    let subtotal = 0;
    let countRows = 0;
    $('.product').each(function () {
        subtotal += parseFloat($(this).find('.product-line-price').text().trim().replace(/\s+/, ''));
        countRows++;
    });
    const tax = subtotal * taxRate;
    const total = subtotal + tax;
    $('.totals-value').fadeOut(fadeTime, () => {
        $('.cart-subtotal').html(subtotal.toFixed(2));
        $('.cart-tax').html(tax.toFixed(2));
        $('.cart-total').html(total.toFixed(2));
        $('.block-cart__counter span').text(countRows);
        if (total == 0) {
            $('.js-checkout').addClass('hidden');
        }
        else {
            $('.js-checkout').removeClass('hidden');
        }
        $('.totals-value').fadeIn(fadeTime);
    });
}
function updateQuantity(quantityInput) {
    const $productRow = $(quantityInput).parents('tr');
    const price = parseFloat($productRow.find('.product-price').text().replace(/\s+/, ''));
    let quantity = Number(quantityInput.value);
    const id = $productRow.data('id');
    const part_number = $productRow.find('.product-item').text();
    const part_type = $productRow.find('.product-type').text();
    const minRequiredQuantity = Number(quantityInput.min);
    const supplier = $productRow.find('.product-supplier').text();
    if (quantity < minRequiredQuantity) {
        quantity = minRequiredQuantity;
        quantityInput.value = String(quantity);
    }
    const linePrice = price * quantity;
    const fullPrice = price * (1 + taxRate) * quantity;
    $productRow.find('.product-line-price').each(function () {
        $(this).fadeOut(fadeTime, function () {
            $(this).text(linePrice.toFixed(2));
            $productRow.find('.product-full-price').text(fullPrice.toFixed(2));
            const priceWithTax = parseFloat($productRow.find('.catalog-price').text().replace(/\s+/, ''));
            recalculateCart();
            addProductToCart(id, part_number, quantity, price, part_type, supplier, minRequiredQuantity, priceWithTax);
            $(this).fadeIn(fadeTime);
        });
    });
}
function removeProductFromCart(id) {
    const cart = JSON.parse(sessionStorage.getItem(cartVersion) ?? '');
    if (!cart) {
        return;
    }
    delete cart[id];
    sessionStorage.setItem(cartVersion, JSON.stringify(cart));
    redRawCount();
}
$(() => {
    redRawCount();
    fillCart();
    $('.product-quantity').on('change', ({ target }) => {
        updateQuantity(target);
    });
    $('#cart_form').on('click', '.js-remove-item', (e) => {
        removeItem(e.target);
    });
});
const getECommerceData = () => {
    const cartValue = sessionStorage.getItem(cartVersion) ?? '{}';
    const cart = JSON.parse(cartValue);
    const entries = Object.entries(cart);
    if (!entries.length)
        return null;
    return {
        currency: 'EUR',
        value: Object.values(cart).reduce((acc, next) => acc + next.quantity, 0),
        items: entries.map(([id, item], index) => {
            return {
                index,
                item_name: item.item,
                item_id: id,
                item_brand: item.type,
                quantity: item.quantity,
                price: item.priceWithVat ?? +(item.price * (1 + taxRate) * item.quantity).toFixed(2),
            };
        })
    };
};
const viewCartHandler = () => {
    const data = getECommerceData();
    if (data)
        ecommerce.viewCart(data);
};
const checkoutHandler = () => {
    $('.js-checkout').on('click', () => {
        const data = getECommerceData();
        if (data)
            ecommerce.beginCheckout(data);
    });
};
document.addEventListener('DOMContentLoaded', viewCartHandler);
document.addEventListener('DOMContentLoaded', checkoutHandler);
