import 'bootstrap/js/collapse';
import 'bootstrap/js/dropdown';
import 'element-closest-polyfill';
import { addProductToCart, redRawCount } from './front/cart';
import Swal from './front/sweetalert/polyfilled-sweetalert2';
import { isHtmlFileInput } from './HtmlPredicates/predicates';
import './stimulus-bootstrap';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
$(() => {
    const $clientRequestFormPlaceholder = $('.js-client-request-form');
    $clientRequestFormPlaceholder.on('click', '.js-client-request-submit', (e) => {
        e.preventDefault();
        const { target } = e;
        const form = target.form;
        const url = form?.action;
        const formData = new FormData();
        form.querySelectorAll('input,textarea').forEach((input) => {
            if (!isHtmlFileInput(input)) {
                formData.append(input.name, input.value);
                return;
            }
            if (isHtmlFileInput(input) && input.files?.length) {
                formData.append(input.name, input.files[0], input.files[0].name);
                return;
            }
        });
        $(target).prop('disabled', true);
        $.ajax({
            url,
            data: formData,
            type: 'post',
            processData: false,
            contentType: false,
        })
            .done((response) => {
            $clientRequestFormPlaceholder.html(response);
            showFiles();
        })
            .fail(() => {
            $(target).prop('disabled', false);
        });
    });
    $('.js-submit-search').on('click', function () {
        const $partNumberInput = $('.js-part-number');
        const input = $partNumberInput.val();
        if (input == '') {
            Swal.fire('Įveskite kodą');
            return false;
        }
        const removedWhiteSpaceInput = input.trim().replace(/\s/g, '');
        $partNumberInput.val(removedWhiteSpaceInput);
        $('#search_form').trigger('submit');
    });
    const _global = (window ?? global);
    _global.addProductToCart = addProductToCart;
    _global.redRawCount = redRawCount;
    redRawCount();
    const gdprCloseButton = document.querySelector('.js-g-d-p-r-close-button');
    if (gdprCloseButton) {
        gdprCloseButton.addEventListener('click', function (e) {
            e.preventDefault();
            const gdprBanner = document.querySelector('.js-g-d-p-r-banner');
            if (gdprBanner) {
                gdprBanner.hidden = true;
            }
            const d = new Date();
            d.setFullYear(d.getFullYear() + 5);
            document.cookie = `gdpr=1; expires=${d.toUTCString()}; path=/; secure=1; SameSite=Lax`;
        });
    }
    const header = document.querySelector('.navbar-sticky');
    const html = document.documentElement;
    const body = document.body;
    if (header) {
        window.addEventListener('scroll', function (e) {
            let scrollTop = html.scrollTop || (body && body.scrollTop) || 0;
            scrollTop -= html.clientTop;
            if (scrollTop > 150) {
                header.classList.add('scrolled');
            }
            else {
                if (scrollTop < 120) {
                    header.classList.remove('scrolled');
                }
            }
        });
    }
    new Swiper('.top-slider__container', {
        slidesPerView: 'auto',
        modules: [Navigation, Pagination],
        navigation: {
            prevEl: document.querySelector('.top-slider__prev'),
            nextEl: document.querySelector('.top-slider__next'),
        },
        pagination: {
            el: document.querySelector('.top-slider__progress'),
            type: 'progressbar',
        },
    });
    new Swiper('.popular__container', {
        slidesPerView: 1,
        modules: [Navigation],
        breakpoints: {
            671: {
                slidesPerView: 2,
            },
            991: {
                slidesPerView: 3,
            },
        },
        spaceBetween: 2,
        navigation: {
            prevEl: document.querySelector('.popular__prev'),
            nextEl: document.querySelector('.popular__next'),
        },
    });
    const nums = document.querySelectorAll('.search-results__number');
    if (nums.length) {
        nums.forEach((num) => {
            const add = num.querySelector('.js-quantity-plus');
            const sub = num.querySelector('.js-quantity-minus');
            const count = num.querySelector('.js-quantity');
            if (add && sub && count) {
                const minVal = parseInt(count.getAttribute('min') || '');
                add.addEventListener('click', (e) => {
                    e.preventDefault();
                    count.valueAsNumber++;
                    count.dispatchEvent(new Event('change'));
                });
                sub.addEventListener('click', (e) => {
                    e.preventDefault();
                    if (count.valueAsNumber > minVal) {
                        count.valueAsNumber--;
                        count.dispatchEvent(new Event('change'));
                    }
                });
            }
        });
    }
    const moreButtons = document.querySelectorAll('.js-show-more');
    if (moreButtons.length) {
        moreButtons.forEach((btn) => {
            const items = btn.closest('.section')?.querySelectorAll(`.${btn.dataset.items}`);
            const count = parseInt(btn.dataset.count || '') || 10;
            if (items?.length) {
                const hiddenItems = Array.from(items).filter((item) => getComputedStyle(item).display == 'none');
                if (!hiddenItems.length) {
                    btn.style.display = 'none';
                }
                window.addEventListener('resize', () => {
                    const hiddenItems = Array.from(items).filter((item) => getComputedStyle(item).display == 'none');
                    if (!hiddenItems.length) {
                        btn.style.display = 'none';
                    }
                });
                btn.addEventListener('click', () => {
                    const hiddenItems = Array.from(items).filter((item) => getComputedStyle(item).display == 'none');
                    if (!hiddenItems.length)
                        return;
                    let i;
                    for (i = 0; i < count; i++) {
                        if (!hiddenItems[i]) {
                            btn.style.display = 'none';
                            break;
                        }
                        hiddenItems[i].classList.add('active');
                    }
                    if (!hiddenItems[i]) {
                        btn.style.display = 'none';
                    }
                });
            }
        });
    }
    const addBtns = document.querySelectorAll('.js-add-to-cart');
    if (addBtns.length) {
        addBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                setTimeout(() => {
                    btn.classList.add('active');
                }, 300);
            });
        });
    }
    $('select:not(.js-select-city):not(.js-select-parcel-machine-address)').each(function () {
        const $this = $(this), numberOfOptions = $(this).children('option').length;
        const $className = $this.attr('class');
        $this.addClass('hidden');
        $this.wrap(`<div class="${$className}"></div>`);
        $this.after('<div class="styled-select"><span></span></div>');
        const $styledSelect = $this.next('div.styled-select');
        const $selectedOption = $this.children('option[selected]');
        $styledSelect
            .find('span')
            .eq(0)
            .text($selectedOption.length ? $selectedOption.eq(0).text() : $this.children('option').eq(0).text());
        const $list = $('<ul />', {
            class: 'form__options',
        }).insertAfter($styledSelect);
        $list.hide();
        for (let i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val(),
                class: $this.children('option').eq(i).attr('disabled')
                    ? 'disabled'
                    : $this.children('option').eq(i).attr('selected')
                        ? 'selected'
                        : '',
            }).appendTo($list);
        }
        const $listItems = $list.children('li:not(.disabled)');
        if (!$selectedOption.length) {
            $listItems.eq(0).addClass('selected');
        }
        $styledSelect.closest('.form__select').on('click', function (e) {
            e.stopPropagation();
            const $item = $(this).find('.styled-select');
            $('div.styled-select.active').each(function () {
                if ($(this).parent().find('select')[0].id != $item.parent().find('select')[0].id) {
                    $(this).removeClass('active').next('ul.form__options').hide();
                }
            });
            $item.toggleClass('active').next('ul.form__options').toggle();
            $('.chosen-container-single').removeClass('chosen-with-drop chosen-container-active');
        });
        $listItems.on('click', function (e) {
            e.stopPropagation();
            $styledSelect.find('span').eq(0).text($(this).text());
            $styledSelect.removeClass('active');
            $listItems.removeClass('selected');
            $(this).addClass('selected');
            $this.val($(this).attr('rel'));
            $this.trigger('change');
            $list.hide();
        });
        $(document).on('click', function () {
            $styledSelect.removeClass('active');
            $list.hide();
        });
    });
    $('form:not([novalidate]) [type="submit"]').on('click', function (e) {
        const form = e.target.closest('form');
        if (!form)
            return;
        const inputs = form?.querySelectorAll('input:not([type="checkbox"]):not([type="radio"])');
        inputs.forEach((input) => {
            if (!input.value.trim()) {
                input.value = '';
            }
        });
    });
    function createFileItem(name, input) {
        const item = document.createElement('span');
        item.className = 'file-item';
        item.textContent = name;
        item.onclick = () => {
            $(item).closest('.form-group').find('.file-item').remove();
            input.dispatchEvent(new Event('change'));
        };
        $(input).closest('.form-group').find('.form__file-label span').hide();
        $(input).closest('.form-group').find('.form__file-label').append(item);
    }
    function updateInput(input) {
        $(input).closest('.form-group').find('.file-item').remove();
        if (input.files?.length) {
            Array.from(input.files).forEach((file) => {
                createFileItem(file.name, input);
            });
        }
        else {
            $(input).closest('.form-group').find('.file-item').remove();
            $(input).closest('.form-group').find('.form__file-label span').show();
        }
    }
    function showFiles() {
        const fileInputs = document.querySelectorAll('.form__file-input');
        if (fileInputs.length) {
            fileInputs.forEach(input => {
                updateInput(input);
                input.addEventListener('change', function () {
                    updateInput(input);
                });
            });
        }
    }
    showFiles();
});
